@import './constants';

.dialog-title {
  background-color: $blue;
  color: $white;
  min-width: 200px;
  border-radius: 0px 0px 30px 0px;
}

.dialog-title-user {
  background-color: $blue;
  color: $white;
  width: 250px;
  border-radius: 0px 0px 30px 0px;
}

.dialog-title-division {
  background-color: $blue;
  color: $white;
  width: 250px;
  border-radius: 0px 0px 30px 0px;
}

.dialog-title-deletion {
  background-color: $red;
  color: $white;
  width: 250px;
  border-radius: 0px 0px 30px 0px;
}

.action-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.close-button {
  margin-right: auto;
}

.change-password-container {
  cursor: pointer;
}

.image-upload {
  cursor: pointer;
}

.image-upload:hover {
  opacity: 0.5;
}
