@import './constants';

.site-picker-container {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 5px 0px 10px 0px;

  .site-name {
    padding: 0px 10px 0px 10px;
    color: $blue;
  }
}
