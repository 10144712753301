.map-wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 70vh;
}

.map-select-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.map-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 180px;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

// to align icon
.custom-div-icon svg {
  position: absolute;
  width: 18px;
  font-size: 22px;
  margin-left: 6px;
  text-align: center;
}

.marker-cluster-small {
  font-size: 0.6vw;
  color: #000000;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-cluster-medium {
  font-size: 0.6vw;
  color: #000000;
  background-color: transparent !important;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-cluster-large {
  font-size: 0.6vw;
  color: #000000;
  background-color: transparent !important;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-cluster-large-xl {
  font-size: 0.6vw;
  color: #000000;
  background-color: transparent !important;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markerClusterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-data {
  align-self: flex-end;
  color: white;
  cursor: pointer;
}

.icon-on {
  animation-name: iconOn;
  animation-duration: 1000ms;
  animation-iteration-count: initial;
  width: 70;
  height: 70;
  margin: 10px;
}

.icon-off {
  animation-name: iconOff;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  width: 70;
  height: 70;
  margin: 10px;
  filter: grayscale(100%);
}

@keyframes iconOn {
  0% {
    filter: grayscale(50%);
  }
  100% {
    filter: grayscale(0%);
  }
}

@keyframes iconOff {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(100%);
  }
}

.filter-input-wrapper {
  .MuiSvgIcon-root {
    color: white;
  }
}
