@import './_constants.scss';

.three-state-wrapper {
  display: flex;
  justify-content: center;
  height: 20px;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  width: 51px;
  height: 20px;
  background-color: $blue;
  color: $white;
  border-radius: 30px 30px 30px 30px;
  margin: 0 10px 0 10px;
}

.selector-yes {
  cursor: pointer;
}

.selector-no {
  cursor: pointer;
}

.selector {
  background-color: $white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  position: absolute;
}
.selector-one {
  left: 2px;
}

.selector-two {
  left: 18px;
}

.selector-three {
  left: 33px;
}

.selector-1 {
  width: 17px;
  height: 20px;
  cursor: pointer;
}
.selector-2 {
  width: 17px;
  height: 20px;
  cursor: pointer;
}
.selector-3 {
  width: 17px;
  height: 20px;
  cursor: pointer;
}
