$blue: #00a6eb;
$light-blue: #e7edf3;
$anthracite: #484848;
$medium-grey: #8a8a8a;
$light-grey: #c5c6c6;
$ultra-light-grey: #f4f4f4;
$white: #ffffff;
$black: #000000;
$red: #ad131d;

$success: #51bf30;
$error: #ff525a;

$lighten1: rgba(255, 255, 255, 0.25);
$lighten2: rgba(255, 255, 255, 0.5);
$lighten3: rgba(255, 255, 255, 0.8);

$darken1: rgba(0, 0, 0, 0.12);
$darken2: rgba(0, 0, 0, 0.24);
$darken3: rgba(0, 0, 0, 0.38);
$darken4: rgba(0, 0, 0, 0.5);
$darken5: rgba(0, 0, 0, 0.85);

:export {
  blue: $blue;
  lightBlue: $light-blue;
  anthracite: $anthracite;
  mediumGrey: $medium-grey;
  lightGrey: $light-grey;
  white: $white;
  black: $black;
}
