@import './constants';

.site-picker-plus-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: -30px 0px 5px 0px;
}

.plus-container {
  margin: 0px 0px -10px 20px;
}

.arrow {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.right {
  transform: rotate(270deg);
}

.wcs_inactive {
  font-weight: inherit;
}

.wcs_active {
  font-weight: bold !important;
}
