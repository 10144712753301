@import './constants';

$header-height: 64px;

body,
html,
#root {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll !important;
  padding-right: 0px !important;
}

.header-offset {
  position: relative;
  height: calc(100vh - #{$header-height});
  margin-top: $header-height;
}

.header-container {
  height: $header-height;
}

.MuiDialogContent-root {
  padding: 15px 24px !important;
}

.clickable {
  cursor: pointer;
}

.hover-grow {
  &:hover {
    transform: scale(1.1);
  }
}

.add-new-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span,
  p {
    color: $blue;
  }

  span {
    margin-left: 5px;
  }
}

.add-new-icon {
  cursor: pointer;
  width: 30px;
  &:hover {
    transform: scale(1.1);
  }
}
