@import './_constants.scss';

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  backdrop-filter: blur(5px);

  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: left;
      }
    }
  }
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  margin-right: 10px;
}
