.cluster-container {
  position: absolute;
  background-color: transparent !important;
  margin-top: -10px;
  margin-left: -5px;

  li {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0% 100%;
  }

  li:first-child {
    transform: rotate(0deg) skewY(-60deg);
  }

  li:nth-child(2) {
    transform: rotate(30deg) skewY(-60deg);
  }

  li:nth-child(3) {
    transform: rotate(60deg) skewY(-60deg);
  }

  li:nth-child(4) {
    transform: rotate(90deg) skewY(-60deg);
  }

  li:nth-child(5) {
    transform: rotate(120deg) skewY(-60deg);
  }

  li:nth-child(6) {
    transform: rotate(150deg) skewY(-60deg);
  }

  li:nth-child(7) {
    transform: rotate(180deg) skewY(-60deg);
  }

  li:nth-child(8) {
    transform: rotate(210deg) skewY(-60deg);
  }

  li:nth-child(9) {
    transform: rotate(240deg) skewY(-60deg);
  }

  li:nth-child(10) {
    transform: rotate(270deg) skewY(-60deg);
  }

  li:nth-child(11) {
    transform: rotate(300deg) skewY(-60deg);
  }

  li:nth-child(12) {
    transform: rotate(330deg) skewY(-60deg);
  }
}

.circle-small {
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-medium {
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-top {
  position: absolute;
  width: 60% !important;
  height: 60% !important;
  border-radius: 50%;
  z-index: 2;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter {
  font-size: 15px;
  color: black;
}
