@import './_constants.scss';

.tabbar {
  padding: 0 32px;
  border-bottom: 1px solid $light-grey;
}

/*
 * TILE CHARTS
 */
.tile-chart {
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 130px;

  .title {
    font-size: 18px;
  }
  .info {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .metric {
    &:not(:last-child) {
      margin-right: 32px;
    }
    .value {
      font-size: 48px;
      font-weight: 600;
      margin-right: 8px;
      line-height: 48px;
    }
    .unit {
      font-size: 25px;
      font-weight: 600;
      opacity: 0.5;
    }
    .value.success,
    .unit.success {
      color: $success;
    }
    .value.error,
    .unit.error {
      color: $error;
    }
    .description {
      font-size: 12px;
      font-weight: 600;
      text-align: right;
      line-height: 12px;
      opacity: 0.5;
    }
  }
}

/*
 * FILTER BAR CONTROLS
 */
.filterbar-item {
  margin: 4px;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.filter-panel,
.filter-panel[class*='root'] {
  box-shadow: none;
  outline: none;

  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before {
    display: 'none';
  }
  &[class*='expanded'] {
    margin: auto;
  }

  .filter-panel-title {
    flex-basis: 33.33%;
    flex-shrink: 0;
    line-height: 1.85;
    margin-right: 24px;
  }

  .filter-panel-details {
    padding: 0;
  }
}

/**
 * TREE COMPONENT
 */

.filter-tree-header {
  background-color: #f5f5f5;
}

.filter-tree-root,
.filter-tree-root-light {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &.filter-tree-root {
    background-color: #fafafa;
  }
  &.filter-tree-root-light {
    background-color: #ffffff;
  }

  ul[class*='container'],
  ul[class*='group'] {
    margin-left: 16px;
  }

  div[class*='label'] {
    padding: 0;
  }

  .filter-tree-item {
    background-color: transparent;
    overflow-x: scroll;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    & > [class*='content'],
    &:hover > [class*='content'],
    &:focus > [class*='content'],
    &[class*='selected'] > [class*='content'] {
      // flex-direction: row-reverse;
      background-color: transparent;

      & > [class*='iconContainer'] {
        display: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .filter-tree-item-expandIcon,
    .filter-tree-item-collapseIcon {
      display: none;
      color: rgba(0, 0, 0, 0.54);
    }

    &[aria-expanded='true']
      > [class*='content']
      > [class*='label']
      > .filter-tree-item-labelContent
      > .filter-tree-item-iconContainer
      > .filter-tree-item-expandIcon {
      display: block;
    }
    &[aria-expanded='false']
      > [class*='content']
      > [class*='label']
      > .filter-tree-item-labelContent
      > .filter-tree-item-iconContainer
      > .filter-tree-item-collapseIcon {
      display: block;
    }
  }
}

/**
 * DRILLDOWN TABLE
 */
.flexed-table-row[class*='Row-root'] {
  display: flex;
  width: 100%;
}
.flexed-table-cell[class*='Cell-root'] {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  // first cell (column) should have fixed width
  &:first-child {
    width: 190px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

/**
 * MISCELLANEOUS
 */
.site-selection {
  background-color: $blue;
  border-radius: 4px;
}

.site-selection-chip {
  background-color: $lighten1 !important;

  &:not(:last-child) {
    margin-right: 4px;
  }
}

.filter-selection {
  background-color: $ultra-light-grey;
  border-radius: 4px;
}

.filter-selection-chip {
  background-color: $darken1 !important;

  &:not(:last-child) {
    margin-right: 4px;
  }
}

.filter-options {
  background-color: $ultra-light-grey;
  border-radius: 4px;
}

/*
 * CHART COMPONENT
 */
.chart-legend-button {
  margin-right: 2px;
  margin-bottom: 1px;

  &.chart-legend-button-hidden {
    .chart-legend-key {
      opacity: 0.5;
    }
    .chart-legend-text {
      text-decoration: line-through;
    }
  }

  .chart-legend-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    margin-right: 4px;
  }
  .chart-legend-text {
    color: #8a8a8a;
    font-size: 11px;
    text-transform: none;
  }
}

.icon-small {
  width: 20px !important;
  height: 20px !important;
}
