@import './_constants.scss';

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.site-picker {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: -30px 0px 5px 0px;
}

.tabbar {
  padding: 0 32px;
  border-bottom: 1px solid $light-grey;
}

.container-box {
  display: block;
  grid-template-rows: 1fr;
  row-gap: 15px;
}

.workdays-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-container {
  margin-top: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
}

.sap-information {
  display: flex;
  align-items: center;
}

.controlbar-container {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 10px;
}

.controlbar-month-selector {
  display: flex;
  align-items: center;
  button {
    padding: 6px;
  }
}

.controlbar-month-selector-label {
  font-size: 18px !important;
  font-weight: bold !important;
}

.controlbar-leyend-input {
  margin-right: 10px !important;

  div div {
    padding-top: 0 !important;
  }
  input {
    display: none;
  }
}

.controlbar-data-button-message {
  display: flex;
  align-items: center;
  background-color: #f3dcc6 !important;
  border-radius: 6px;
  color: #ee7523 !important;
  border: solid 1px #ee7523;
  margin-right: 20px !important;
  padding: 6px;
}

.controlbar-data-button {
  display: flex;
  align-items: center;
  background-color: #e0e0e0 !important;
  color: #484848 !important;
  margin-right: 10px !important;
}

.controlbar-data-button-confirm {
  display: flex;
  align-items: center;
  background-color: $blue !important;
  color: white !important;
  margin-right: 10px !important;
}

.capacitiesTableCell-greyBold {
  color: $medium-grey;
  font-weight: bold;
}

.capacitiesTableCell-blackBold {
  color: $black;
  font-weight: bold;
}

.controlbar-data-button-confirm:disabled {
  display: flex;
  align-items: center;
  opacity: 0.5;
  background-color: #e0e0e0 !important;
  color: #484848 !important;
  margin-right: 10px !important;
}

.capacityGroupHeaderRowTableCell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 0 8px;
}

.capacityGroupRowTableCell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  padding: 0 8px;
  color: #9e9e9e;
}

.capacitiesTableCell-blackValue {
  color: $black;
}

.capacitiesTableCell-newValue {
  color: $blue;
}

.capacitiesTableCell-greenValue {
  background-color: #d7f3ce;
  border-radius: 6px;
  color: $success;
  padding: 3px;
}

.capacitiesTableCell-redValue {
  background-color: #eacacb;
  border-radius: 6px;
  color: $error;
  padding: 3px;
}

.capacitiesTableCell-newWorkcenter {
  color: $blue;
  font-weight: bold;
}

.capacitiesTableCell-noValue {
  color: $medium-grey;
}

.capacitiesTableCell-noValueFill {
  display: flex;
  height: 100%;
}

.capacitiesTableCell-errorValue {
  color: $error;
}

.capacitiesTableCell-bold {
  font-weight: bold;
  cursor: pointer;
}

.capacitiesTableCell-editMappingButton {
  padding-left: 5px;
}

.capacitiesTableHeader-actualMonth {
  color: $blue;
}

.capacitiesTableHeader-lastCol {
  border-right: $blue 2px solid;
}

.capacitiesTableHeader-lastCol-header {
  color: $blue;
  border-right: $blue 2px solid;
}

.capacitiesTabs-editModalHeaderOn {
  display: block;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(3px);
  z-index: 2000;
}
.capacitiesTabs-editModalHeaderOff {
  display: none;
}
.capacitiesTabs-editModalOn {
  display: block;
  position: absolute;
  top: -64px;
  left: 0;
  width: 100%;
  height: 243px;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(3px);
  z-index: 2000;
}
.capacitiesTabs-editModalOff {
  display: none;
}
