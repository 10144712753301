.header-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-icon {
  margin-right: auto;
}

.icon-container,
.icon-container-active {
  height: 64px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;

  a {
    display: flex;
    align-content: center;
  }
}

.icon-container-active {
  border-radius: 100% 100% 0% 0%;
  background-color: white;
}

.menu-icon {
  height: 50px;
  width: 50px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;
}

.header-link {
  text-decoration: none;
  color: #000000;
}
