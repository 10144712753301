@import './constants';

.sync-text {
  font-size: smaller;
  color: gray !important;
}

.sync-badge {
  margin-left: 30px !important;
  margin-right: 5px !important;
}
