.login-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.login-form {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.recover-password-box {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 30%;
}

.recover-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
}
